

/**
 * 找不到頁面
 */
const NotFound = () => (
    <div>
        <h2>404</h2>
        <p>
                This page could not be found.
        </p>
    </div>
);

export default NotFound;



